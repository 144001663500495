import {Down16, Up16} from "@bphxd/ds-core-react/lib/icons";
import {
  COUNTRY_NORWAY,
  COUNTRY_POLAND,
  COUNTRY_SPAIN,
  COUNTRY_SWEDEN,
  COUNTRY_UK,
} from "constants/countryDetails";
import {DIV_CODE_COPRO} from "constants/divisionDetails";
import PropTypes from "prop-types";
import {useAppSetting} from "providers/appSetting";
import {formatNumber, useUserSettings} from "providers/userSettings";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {
  Button,
  FormGroup,
  FormText,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import {parseAndRoundToThreeDecimal} from "utils/numberUtil";
import {MB_QUANTITY_UNIT_MJ, status} from "../constants";

const unitToBeDisplayed = MB_QUANTITY_UNIT_MJ;

const OutboundDetail = ({data, rowId, subRowId, onSelectionChange}) => {
  const [currentIndex, setCurrentIndex] = useState(subRowId);
  const {outboundRecords} = data;
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const {appSetting} = useAppSetting();

  const {division} = useParams();
  const divisionCode = division.toUpperCase();

  const [epToolTip, setEpToolTip] = useState();
  const [etdToolTip, setEtdToolTip] = useState();

  const outboundRecord = useMemo(() => {
    const obRec = {...outboundRecords[currentIndex]};
    let calculationSteps = obRec.calculationSteps ?? "None";
    if (calculationSteps === "None") {
      obRec.calculationSteps = {};
      return obRec;
    }
    calculationSteps = JSON.parse(calculationSteps.replaceAll("'", '"'));
    obRec.calculationSteps =
      calculationSteps[
        obRec.unitToBeDisplayed === null
          ? unitToBeDisplayed
          : obRec.unitToBeDisplayed
      ];
    return obRec;
  }, [currentIndex, outboundRecords]);

  const numberFormatter = useCallback(
    (value) => {
      if (Number.isInteger(value) && value !== 0) {
        return formatNumber(
          parseAndRoundToThreeDecimal(value),
          decimalFormat,
          3,
        );
      }
      if (value === 0 || value === "0.0") {
        return formatNumber(
          parseAndRoundToThreeDecimal(value),
          decimalFormat,
          0,
        );
      }

      return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
    },
    [decimalFormat],
  );

  useEffect(() => {
    if (outboundRecord) {
      switch (divisionCode) {
        case DIV_CODE_COPRO:
          setEpToolTip(`${outboundRecord.calculationSteps?.ghg_ep ?? ""}`);
          setEtdToolTip(`${outboundRecord.calculationSteps?.ghg_etd ?? ""}`);
          break;
        default:
          setEpToolTip(
            `${numberFormatter(outboundRecord.ghgEp)} (Declaration)`,
          );
          setEtdToolTip(
            `${numberFormatter(outboundRecord.ghgEp)} (Declaration)`,
          );
          break;
      }
    }
  }, [divisionCode, outboundRecord, numberFormatter]);

  const showOutgoingView = useMemo(() => {
    switch (appSetting.currentCountry.toLowerCase()) {
      case COUNTRY_SWEDEN.toLowerCase():
        return outboundRecord.status !== status.AVAILABLE;
      case COUNTRY_NORWAY.toLowerCase():
        return outboundRecord.status !== status.AVAILABLE;
      case COUNTRY_SPAIN.toLowerCase():
        return true;
      case COUNTRY_UK.toLowerCase():
        return false;
      case COUNTRY_POLAND.toLowerCase():
        return false;
      default:
        return false;
    }
  }, [appSetting.currentCountry, outboundRecord.status]);

  return (
    <div className="flex flex-col h-[260px] w-full gap-4 items-stretch justify-between">
      <div className="flex flex-row justify-between gap-2 mt-[52px]">
        {showOutgoingView && (
          <>
            <FormGroup>
              <Label for="ghgEecOut" className="text-[12px] mb-0">
                Eec
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEecOut"
                data-test="ghgEecOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEec)}
              />
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              +
            </div>
            <FormGroup>
              <Label for="ghgElOut" className="text-[12px] mb-0">
                El
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgElOut"
                data-test="ghgElOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEl)}
              />
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              +
            </div>
            <FormGroup>
              <Label for="ghgEpOut" className="text-[12px] mb-0">
                Ep
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEpOut"
                data-test="ghgEpOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEp)}
              />
              {epToolTip && (
                <UncontrolledTooltip placement="bottom" target="ghgEpOut">
                  {epToolTip}
                </UncontrolledTooltip>
              )}
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              +
            </div>
            <FormGroup>
              <Label for="ghgEtdOut" className="text-[12px] mb-0">
                Etd
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEtdOut"
                data-test="ghgEtdOut"
                readOnly
                value={
                  outboundRecord.ghgEtd === "DDV" ||
                  outboundRecord.ghgEtd === "TDV"
                    ? outboundRecord.ghgEtd
                    : numberFormatter(outboundRecord.ghgEtd)
                }
              />
              {etdToolTip && (
                <UncontrolledTooltip placement="bottom" target="ghgEtdOut">
                  {etdToolTip}
                </UncontrolledTooltip>
              )}
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              +
            </div>
            <FormGroup>
              <Label for="ghgEuOut" className="text-[12px] mb-0">
                Eu
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEuOut"
                data-test="ghgEuOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEu)}
              />
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              -
            </div>
            <FormGroup>
              <Label for="ghgEscaOut" className="text-[12px] mb-0">
                Esca
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEscaOut"
                data-test="ghgEscaOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEsca)}
              />
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              -
            </div>
            <FormGroup>
              <Label for="ghgEccsOut" className="text-[12px] mb-0">
                Eccs
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEccsOut"
                data-test="ghgEccsOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEccs)}
              />
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              -
            </div>
            <FormGroup>
              <Label for="ghgEccrOut" className="text-[12px] mb-0">
                Eccr
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEccrOut"
                data-test="ghgEccrOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEccr)}
              />
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              =
            </div>
            <FormGroup>
              <Label for="ghgTotalOut" className="text-[12px] mb-0">
                Total
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgTotalOut"
                data-test="ghgTotalOut"
                readOnly
                value={
                  outboundRecord.ghgTotal === "DDV" ||
                  outboundRecord.ghgTotal === "TDV"
                    ? outboundRecord.ghgTotal
                    : numberFormatter(outboundRecord.ghgTotal)
                }
              />
              <div className="text-right">
                <FormText>
                  {outboundRecord.unitToBeDisplayed === null
                    ? unitToBeDisplayed
                    : outboundRecord.unitToBeDisplayed}
                </FormText>
              </div>
            </FormGroup>
          </>
        )}
      </div>

      {outboundRecords.length > 1 && (
        <div className="flex flex-row justify-end items-center">
          <div className="flex flex-row justify-end gap-2 items-center">
            <Button
              color="link"
              size="sm"
              className="!text-[#111111a3] !pr-[30px] !text-[11px]"
              onClick={() => {
                setCurrentIndex(currentIndex - 1);
                onSelectionChange(`${rowId}_${currentIndex - 1}`);
              }}
              disabled={currentIndex === 0}
            >
              <Up16 className="mr-[15px]" />
              PREV
            </Button>
          </div>
          <div className="text-uppercase text-[14px] font-[500] text-[#111111] items-center">
            {currentIndex + 1} of {outboundRecords.length}
          </div>
          <div className="flex flex-row justify-end gap-2 items-center">
            <Button
              color="link"
              size="sm"
              className="!text-[#111111a3] !pr-0 !pl-[30px] !text-[11px]"
              onClick={() => {
                setCurrentIndex(currentIndex + 1);
                onSelectionChange(`${rowId}_${currentIndex + 1}`);
              }}
              disabled={currentIndex === outboundRecords.length - 1}
            >
              NEXT
              <Down16 className="ml-[15px]" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

OutboundDetail.propTypes = {
  data: PropTypes.object,
  rowId: PropTypes.string,
  subRowId: PropTypes.number,
  onSelectionChange: PropTypes.func,
};

export default OutboundDetail;
