import {useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {DIV_CODE_COPRO} from "constants/divisionDetails";
import {
  AssignToSaleAPI,
  GET_LOCATION_DETAILS,
  getCertificateAutoPopulateDetailsAPI,
} from "graphql/MassBalance/Actions/AllocateSale";

import {GET_EU_COUNTRIES} from "graphql/es-co-processing/mass-balance/generateCertificate";
import ResponseModal from "modules/GlobalAllocation/components/ResponseModal";
import Form from "modules/common/FormNew";
import moment from "moment";
import {useAppSetting} from "providers/appSetting/context.js";
import {useUserSettings} from "providers/userSettings/context.js";
import {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Button, Spinner} from "reactstrap";
import dateFunctions from "utils/helpers/dateFunctions";

import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import Layout from "../Layout";
import {
  SchemaGenerateCertificateBPMarketing,
  SchemaGenerateCertificatePOS,
} from "./schemaGenerateCertificate";

import {
  ISCC_EU,
  ISCC_PLUS,
  ISCC_PLUS_351,
  ISCC_PLUS_BP_MARKETING,
  ISCC_POS_26,
  ISCC_SD_26,
} from "../constants";
import ConfirmationPopup from "./ConfirmationPopup";
import GenerateCerificatePagePOS from "./ISCCEU/GenerateCerificatePagePOS";
import GenerateCertificatePageSD from "./ISCCEU/GenerateCertificatePageSD";
import GenerateCertificatePageBPMarketing from "./ISCCPlus/GenerateCertificatePageBPMarketing";
import GenerateCertificatePageISCCplusV351 from "./ISCCPlus/GenerateCertificatePageISCCplusV351";
import "./generateCertificate.css";

const GenerateCertificateDetails = () => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const {appSetting, setAppSetting} = useAppSetting();
  const {country: countryName} = useParams();
  setCountryDetails(countryName);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_COPRO);

  const navigate = useNavigate();
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const [data, setData] = useState(null);

  const location = useLocation();

  const stateDetails = location.state;

  const docDetails = JSON.parse(stateDetails);

  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseHeader, setResponseHeader] = useState("");
  const [responseFooter, setResponseFooter] = useState([]);

  const {data: locationDetails} = useQuery(GET_LOCATION_DETAILS);

  const recipientNameParam =
    docDetails?.recipientReceiptName === "Same as recipient address"
      ? docDetails?.recipientName
      : docDetails?.recipientReceiptName;

  const recipientRecord =
    locationDetails?.bioLcGetBusinessAndLocationDetails?.businessAndLocationDetails?.find(
      (objloc) => objloc.locationCode === recipientNameParam,
    );

  const recipientLegalEntityRecord =
    locationDetails?.bioLcGetBusinessAndLocationDetails?.businessAndLocationDetails?.find(
      (objloc) => objloc.businessPartnerCode === docDetails?.recipientName,
    );

  useEffect(() => {
    async function fetchCertAPI() {
      const {data: dataCert} = await getCertificateAutoPopulateDetailsAPI({
        businessPartnerCode: docDetails?.recipientName,
        mbLocationGroupId:
          docDetails?.rowData.original.inboundRecord.mbLocationGroupId,
        certificationSystem: docDetails?.certificationSystem,
        templateType: docDetails?.templateType,
        vesselName: docDetails?.vesselName,
        dispatchDate: dateFunctions.formatDatePickerDate(
          docDetails?.dispatchDate,
        ),
        inboundEuQtyId:
          docDetails?.rowData?.original?.inboundRecord?.mbInboundQtyEuId,
        motRecipient: docDetails?.motRecipient,
        sdNumber:
          docDetails?.rowData?.original?.inboundRecord?.incomingDocument,
        issueDate: dateFunctions.formatDatePickerDate(docDetails?.issueDate),
        siteReferenceId: docDetails?.siteReferenceId,
        divisionId: docDetails?.divisionId,
      });

      if (dataCert?.bioLcGetMassBalanceAutoPopulatedData?.statusCode === 500) {
        toast.error(dataCert?.bioLcGetMassBalanceAutoPopulatedData?.message);
      }
      setData(dataCert);
    }

    fetchCertAPI();
  }, [docDetails]);

  const {data: countrylist} = useQuery(GET_EU_COUNTRIES);

  const redirectUrl = `/mass-balance/${countryName}/${divisionData?.divisionCode}/${docDetails?.currentFilterValues?.mbBalanceTypeCode}/${docDetails?.currentFilterValues?.mbLocationGroupName}/${docDetails?.currentFilterValues?.mbPeriodName}/${docDetails?.currentFilterValues?.mbBalanceGroupName}`;

  const breadcrumbItems = useMemo(
    () => [
      {text: "BioVerse", link: "/"},
      {text: "Co-processing", link: "/"},
      {
        text: "Mass balance",
        link: redirectUrl,
      },
      {
        text: docDetails?.currentFilterValues?.mbLocationGroupName,
        link: redirectUrl,
      },
      {
        text: data?.bioLcGetMassBalanceAutoPopulatedData?.data
          ?.generatedCertificateId,
      },
    ],
    [
      data?.bioLcGetMassBalanceAutoPopulatedData?.data?.generatedCertificateId,

      redirectUrl,
      docDetails?.currentFilterValues?.mbLocationGroupName,
    ],
  );

  const handleSuccessResponse = () => {
    setResponseHeader(
      "Your certificate is now being generated. We will alert you as soon as it’s ready.",
    );
    setResponseFooter([
      {
        id: 1,
        text: "Return to mass balance",
        buttonClassName: "!text-[#111]",
        action: () => {
          navigate(redirectUrl);
        },
      },
    ]);
    setShowResponseModal(true);
  };

  return (
    <div className="flex flex-col  bp-core bg-light-gray-100">
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 min-h-screen ">
          <Layout
            title="Please review and complete"
            breadcrumbs={breadcrumbItems}
            subtitle={
              <div className="flex flex-col justify-between items-start gap-2 small pb-6 px-7">
                <span className="mb-2">
                  To finalise this sales assignment please review the following
                  details answering any outstanding questions. An example
                  declaration is shown on the right for your reference.
                </span>

                {docDetails?.templateType === ISCC_PLUS_BP_MARKETING &&
                  docDetails?.certificationSystem === "ISCC PLUS" && (
                    <span className="mb-2">
                      Please review the declaration statement with care. The
                      example content in [brackets] should be replaced by you
                      with the information presented on this page.
                    </span>
                  )}

                <span className="mb-2">
                  Once you have completed the review, you can generate the
                  certificate. This will take approximately 20 seconds
                </span>

                <span>Once complete you can return to the mass balance.</span>
              </div>
            }
          >
            <div className="flex flex-col p-2 min-h-[800px] px-7 pt-6">
              <Form
                className="flex flex-col gap-5 generate-certificate-form-details"
                id="generate-certificate-form"
                schema={
                  docDetails?.templateType === ISCC_PLUS_BP_MARKETING &&
                  docDetails?.certificationSystem === "ISCC PLUS"
                    ? SchemaGenerateCertificateBPMarketing
                    : SchemaGenerateCertificatePOS
                }
                onSubmit={async (values) => {
                  setProcessing(true);
                  const params = {
                    mbOutboundQtyEuId:
                      docDetails?.rowData.original.outboundRecords
                        .mbOutboundQtyEuId,
                    siteReferenceId: siteReferenceData?.siteReferenceId,
                    divisionId: divisionData?.divisionId,
                    mbInboundQtyEuId:
                      docDetails?.rowData?.original?.inboundRecord
                        ?.mbInboundQtyEuId,
                    sdNumber:
                      docDetails?.rowData?.original?.inboundRecord
                        ?.incomingDocument,
                    fromLocationGroupId:
                      docDetails?.rowData?.original?.inboundRecord
                        ?.mbLocationGroupId,
                    toLocationCode: docDetails?.recipientReceiptName,
                    docNumberSplit:
                      docDetails?.rowData?.original?.inboundRecord
                        ?.incomingDocument,
                    mbBalanceGroupId:
                      docDetails?.rowData?.original?.inboundRecord
                        ?.mbBalanceGroupId,
                    mbBalanceTypeId:
                      docDetails?.currentFilterValues?.mbBalanceTypeId,
                    mbPeriodStatusId:
                      docDetails?.rowData?.original?.inboundRecord
                        ?.mbPeriodStatusId,
                    additionalInfo: values?.additionalInfo,
                    agriculturalBiomassIntermediateCropFlag:
                      values?.agriculturalBiomassIntermediateCropFlag,
                    agriculturalBiomassLowRiskFlag:
                      values?.agriculturalBiomassLowRiskFlag,
                    certificationSystem: values?.certificationSystem,
                    chainOfCustody: values?.chainOfCustody,
                    contractNumber: values?.contractNumber,
                    createdBy: account.username,
                    dispatchDate: dateFunctions.formatDatePickerDate(
                      values?.dispatchDate,
                    ),
                    euRedCompliantFlag: values?.euRedCompliantFlag,

                    isccCompliantFlag: values?.isccCompliantFlag,
                    issueDate: dateFunctions.formatDatePickerDate(
                      values?.issueDate,
                    ),

                    motRecipient: docDetails?.motRecipient,
                    productType: values?.productType,
                    quantity: values?.feedstockQty,
                    rawMaterialCompliantFlag:
                      values?.materialSustainabilityCriteriaFlag,
                    rawMaterialWasteOrResidueFlag:
                      values?.rawMaterialWasteOrResidueFlag,
                    recipientAddress: values?.recipientAddress,
                    recipientName: recipientRecord?.locationLongName,
                    recipientReceiptAddress:
                      values?.receiptReceivingPointAddress,

                    templateType: docDetails?.templateType,
                    uom: docDetails?.uom,
                    vesselName: docDetails?.vesselName,
                    dateOfInstallation: values?.dateOfInstallation
                      ? moment(values?.dateOfInstallation).format(dateFormat)
                      : "",
                    dateOfInstallation2: values?.dateOfInstallation2
                      ? moment(values?.dateOfInstallation2).format(dateFormat)
                      : "",
                    declarationStatement: values?.declarationStatement,
                    bioProductSpec: values?.bioProductSpec,
                    rawMaterialCatSpec: values?.rawMaterialCatSpec,
                    general: values?.general,
                    issueDateFormatted: moment(values?.issueDate).format(
                      dateFormat,
                    ),
                    dispatchDateFormatted: moment(values?.dispatchDate).format(
                      dateFormat,
                    ),
                    additionalInfoNUTS2region:
                      values?.additionalInfoNUTS2region,
                    allocatedHeat: values?.ghgAllocatedHeat,
                    allocatedElectricity: values?.ghgAllocatedElectricity,
                    conventionalDefaultValue: docDetails?.fossilFuelComparator,
                    userOfBiofuel: values?.IsUserofBioLiquidFuel,
                    producerOfBiofuel: values?.IsProducerOfBiofuelBiomassFuel,
                    recipientLegalEntity:
                      recipientLegalEntityRecord?.businessPartnerLongName,
                    totalDefaultValue: values?.totalDefaultValueRedApplied,

                    supplierName: values?.supplierName,

                    supplierAddress: values?.supplierAddress,
                    supplierDispatchAddress: values?.recipientReceiptAddress,
                  };

                  const paramsBPMarketing = {
                    ...params,
                    rawMaterialType: values?.rawMaterialType,
                    rawMaterialCategory: values?.rawMaterialType,
                    bioFeedstock: values?.bioFeedstock,
                  };

                  const paramsPOS = {
                    ...params,
                    rawMaterialType: values?.rawMaterialType,
                  };

                  const paramsPOSSD = {
                    ...params,
                    rawMaterialType: values?.rawMaterialType,
                    transportationDistanceShip: values?.shipKm,
                    transportationDistanceTrain: values?.trainKm,
                    transportationDistanceRoad: values?.roadKm,
                    disaggregatedDefaultValueOilExtraction:
                      values?.IsDisaggregatedDefaultValueForOilApplied,
                    disaggregatedDefaultValueSoilEmissions:
                      values?.IsDisaggregatedDefaultValueForSoilApplied,
                    escaBonus: values?.animalManureUsedFlag,
                    elBonus:
                      values?.biomassObtainedFromRestoredDegradedLandFlag,
                    biogasIncentives:
                      values?.incentivesReceivedforBiogasProductionFlag,
                    biogasIncentivesDetails: values?.biogasIncentivesDetails,
                  };

                  const paramsISCCPlus351 = {
                    ...params,
                    quantity: values?.certifiedMaterialQty,
                    rawMaterialCompliantFlag:
                      values?.rawMaterialSustainabilityCriteriaFlag,
                    rawMaterialWasteOrResidueFlag:
                      values?.rawMaterialMeetsDefCriteriaFlag,
                    rawMaterialType: values?.rawMaterialType,
                    isccCompliantFlag: values?.isccCompliantFlag,

                    circularRawMaterial: values?.feedstockType === "Circular",
                    circularRawMaterialQuantity:
                      values?.feedstockType === "Circular"
                        ? values?.feedstockQtyInput
                        : "",
                    bioCircularRawMaterial:
                      values?.feedstockType === "Bio-circular",
                    bioCircularRawMaterialQuantity:
                      values?.feedstockType === "Bio-circular"
                        ? values?.feedstockQtyInput
                        : "",
                    bioRawMaterial: values?.feedstockType === "Bio",
                    bioRawMaterialQuantity:
                      values?.feedstockType === "Bio"
                        ? values?.feedstockQtyInput
                        : "",
                    renewableRawMaterial:
                      values?.feedstockType === "Renewable-energy-derived",
                    renewableRawMaterialQuantity:
                      values?.feedstockType === "Renewable-energy-derived"
                        ? values?.feedstockQtyInput
                        : "",

                    deliveryQuantity: values?.deliveryQty,
                    recyclingOperationsType: values?.operationType,
                    wasteStatus: values?.wasteStatus,

                    multiSiteCreditTransfer:
                      values?.multiSiteCreditTransferFlag,
                    isccPlusEmissionsRequirementsFlag:
                      values?.isscGHGEmissionsRequirementFlag,

                    transportationDistanceShip: values?.shipKm,
                    transportationDistanceTrain: values?.trainKm,
                    transportationDistanceRoad: values?.roadKm,

                    isccPlusConsumablesFlag: values?.isccPlusConsumablesFlag,
                    isccPlusNonGMOFeedFlag: values?.isccPlusNonGMOFoodFlag,
                    isccPlusNonGMOTechnicalMarketsFlag:
                      values?.isccPlusNonGMOTechnicalFlag,
                    isccPlusElectricityAndHeatFlag:
                      values?.isccPlusElectricityHeatFlag,
                    isccPlusFSSAddOnFlag: values?.IsccPlusAddonFoodSecurityFlag,

                    cornUseJapaneseDefaultValues:
                      values?.isJapaneseDefaultValuesforBrazilianEthanolUS,
                    cornEmissionsLandUseChargeZero:
                      values?.isEmissionsfromLandUseZeroUS,
                    sugarCaneUseJapaneseDefaultValues:
                      values?.isJapaneseDefaultValuesforBrazilianEthanolBrazil,
                    sugarCaneEmissionsLandUseChargeZero:
                      values?.isEmissionsfromLandUseZeroBrazil,
                  };

                  const getParamValues = (templateType) => {
                    switch (templateType) {
                      case ISCC_POS_26:
                        return paramsPOS;
                      case ISCC_SD_26:
                        return paramsPOSSD;
                      case ISCC_PLUS_BP_MARKETING:
                        return paramsBPMarketing;
                      case ISCC_PLUS_351:
                        return paramsISCCPlus351;
                      default:
                        return params;
                    }
                  };

                  const {data: response} = await AssignToSaleAPI({
                    event: getParamValues(docDetails?.templateType),
                  });
                  if (response?.bioLcCPPMassBalanceAssign?.statusCode === 200) {
                    setProcessing(false);

                    setAppSetting({
                      ...appSetting,
                      subscriptionDataGenerateCertificate: {
                        mbOutboundQtyEuId:
                          response?.bioLcCPPMassBalanceAssign
                            ?.mbOutboundQtyEuId,
                        country: countryName,
                        division: divisionData?.divisionCode,
                        sdNumber:
                          docDetails?.rowData?.original?.inboundRecord
                            ?.incomingDocument,
                        gmbFilterValues: docDetails?.currentFilterValues,
                        docNumber:
                          response?.bioLcCPPMassBalanceAssign
                            ?.generatedDocNumber,
                      },
                    });
                    handleSuccessResponse();
                  } else {
                    setErrorModalVisible(true);
                    setProcessing(false);
                  }
                  return response;
                }}
              >
                {docDetails?.templateType === ISCC_POS_26 &&
                  docDetails?.certificationSystem === ISCC_EU && (
                    <GenerateCerificatePagePOS
                      data={{
                        ...docDetails,
                        ...data?.bioLcGetMassBalanceAutoPopulatedData?.data,
                        locationDetails:
                          locationDetails?.bioLcGetBusinessAndLocationDetails
                            ?.businessAndLocationDetails,
                        countries: countrylist?.bioLcCoproEuGetCountries,
                      }}
                    />
                  )}

                {docDetails?.templateType === ISCC_SD_26 &&
                  docDetails?.certificationSystem === ISCC_EU && (
                    <GenerateCertificatePageSD
                      data={{
                        ...docDetails,
                        ...data?.bioLcGetMassBalanceAutoPopulatedData?.data,
                        locationDetails:
                          locationDetails?.bioLcGetBusinessAndLocationDetails
                            ?.businessAndLocationDetails,
                        countries: countrylist?.bioLcCoproEuGetCountries,
                      }}
                    />
                  )}

                {docDetails?.templateType === ISCC_PLUS_BP_MARKETING &&
                  docDetails?.certificationSystem === ISCC_PLUS && (
                    <GenerateCertificatePageBPMarketing
                      data={{
                        ...docDetails,
                        ...data?.bioLcGetMassBalanceAutoPopulatedData?.data,
                        locationDetails:
                          locationDetails?.bioLcGetBusinessAndLocationDetails
                            ?.businessAndLocationDetails,
                        countries: countrylist?.bioLcCoproEuGetCountries,
                      }}
                    />
                  )}
                {docDetails?.templateType === ISCC_PLUS_351 &&
                  docDetails?.certificationSystem === ISCC_PLUS && (
                    <GenerateCertificatePageISCCplusV351
                      data={{
                        ...docDetails,
                        ...data?.bioLcGetMassBalanceAutoPopulatedData?.data,
                        locationDetails:
                          locationDetails?.bioLcGetBusinessAndLocationDetails
                            ?.businessAndLocationDetails,
                        countries: countrylist?.bioLcCoproEuGetCountries,
                      }}
                    />
                  )}
              </Form>
              <div className="flex flex-row gap-4 my-6 justify-end">
                <Button
                  className="show rounded-0"
                  color="tertiary"
                  outline
                  onClick={() =>
                    navigate(
                      `/mass-balance/${encodeURIComponent(
                        countryName,
                      )}/${DIV_CODE_COPRO}/${
                        docDetails?.currentFilterValues?.mbBalanceTypeCode
                      }/${
                        docDetails?.currentFilterValues?.mbLocationGroupName
                      }/${docDetails?.currentFilterValues?.mbPeriodName}/${
                        docDetails?.currentFilterValues?.mbBalanceGroupName
                      }`,
                    )
                  }
                >
                  Cancel
                </Button>
                <Button
                  className="primary-btn rounded-0"
                  color="standard-primary"
                  form="generate-certificate-form"
                  type="submit"
                  disabled={isProcessing}
                >
                  {isProcessing && (
                    <Spinner size="sm" className="btn-icon-prefix" />
                  )}
                  Generate Certificate
                </Button>
              </div>
            </div>
          </Layout>
        </div>
        <div className="flex flex-col flex-1 items-start justify-stretch bg-light-gray-100">
          {data?.bioLcGetMassBalanceAutoPopulatedData?.data?.templatePdfUrl !=
            null &&
          data?.bioLcGetMassBalanceAutoPopulatedData?.data?.templatePdfUrl.includes(
            "file-not-found",
          ) ? (
            <div className="flex flex-col p-5 w-full text-center">
              <span className="fs-4 text-danger">Unable to load file</span>
              <code className="fs-6 text-danger">File not found</code>
            </div>
          ) : (
            <iframe
              className="w-full min-h-screen"
              title="View document"
              border="0"
              src={
                data?.bioLcGetMassBalanceAutoPopulatedData?.data?.templatePdfUrl
              }
            ></iframe>
          )}
        </div>
        {showResponseModal && (
          <ResponseModal
            showModal={showResponseModal}
            header={responseHeader}
            footerArr={responseFooter}
          />
        )}
        <ConfirmationPopup
          isOpen={isErrorModalVisible}
          title="Error"
          confirmLabel="Return to certificate"
          message={
            <span>
              <p>
                Sorry, we were unable to generate the certificate. You can try
                again by closing this window or cancelling for the time being
              </p>
            </span>
          }
          onConfirm={async () => {
            setErrorModalVisible(false);
          }}
        />
      </div>
    </div>
  );
};

export default GenerateCertificateDetails;
