import {
  DIV_CODE_COPRO,
  DIV_CODE_GF,
  DIV_CODE_SAF,
} from "constants/divisionDetails";
import PropTypes from "prop-types";
import React from "react";
import {actionItems} from "../constants";
import AllocateSale from "./Actions/AllocateSale/AllocateSale";
import AllocateSaleCPP from "./Actions/CPP/AssignToSale";
import ReserveDetailsCPP from "./Actions/CPP/Reserve/ReserveDetails";
import TransferDetailsCPP from "./Actions/CPP/Transfer/TransferDetails";
import RetireForMandate from "./Actions/RetireForMandate/RetireForMandate";
import ReversalModal from "./Actions/ReversalModal";
import WriteOff from "./Actions/WriteOff/WriteOff";
import GenerateCertificateModal from "./GenerateCertificateModal";

const ModalToShow = ({
  modal,
  setModal,
  rowData,
  divisionCode,
  currentFilterValues,
}) => {
  const renderModal = () => {
    switch (modal) {
      case actionItems.WRITE_OFF:
        return (
          <WriteOff
            isVisibleModal
            rowData={rowData}
            divisionCode={divisionCode}
          />
        );
      case DIV_CODE_GF + actionItems.RETIRE_FOR_MANDATE:
        return (
          <RetireForMandate
            isVisibleModal
            rowData={rowData}
            divisionCode={divisionCode}
          />
        );
      case DIV_CODE_GF + actionItems.ALLOCATE:
        return (
          <AllocateSale
            isVisibleModal
            rowData={rowData}
            divisionCode={divisionCode}
          />
        );
      case DIV_CODE_COPRO + actionItems.RESERVE:
      case DIV_CODE_COPRO + actionItems.EDIT_RESERVATION:
        return (
          <ReserveDetailsCPP
            isVisibleModal
            rowData={rowData}
            divisionCode={divisionCode}
          />
        );
      case DIV_CODE_COPRO + actionItems.TRANSFER:
        return (
          <TransferDetailsCPP
            isVisibleModal
            rowData={rowData}
            divisionCode={divisionCode}
            currentFilterValues={currentFilterValues}
          />
        );
      case DIV_CODE_SAF + actionItems.GENERATE_DOCUMENT:
        return (
          <GenerateCertificateModal
            isVisibleModal
            rowData={rowData}
            divisionCode={divisionCode}
            currentFilterValues={currentFilterValues}
          />
        );
      case DIV_CODE_COPRO + actionItems.ALLOCATE:
        return (
          <AllocateSaleCPP
            isVisibleModal
            rowData={rowData}
            divisionCode={divisionCode}
            currentFilterValues={currentFilterValues}
          />
        );
      default:
        return (
          <ReversalModal
            modal={modal}
            setModal={setModal}
            rowData={rowData}
            divisionCode={divisionCode}
            currentFilterValues={currentFilterValues}
          />
        );
    }
  };

  return <>{renderModal()}</>;
};

ModalToShow.propTypes = {
  modal: PropTypes.string,
  setModal: PropTypes.func,
  rowData: PropTypes.object,
  divisionCode: PropTypes.string,
  currentFilterValues: PropTypes.object,
};

export default ModalToShow;
