import {Down24, Kebab24, Up24} from "@bphxd/ds-core-react/lib/icons";
import {docStatusClassNames, docStatusMessages} from "content/DocStatus";
import moment from "moment";
import PropTypes from "prop-types";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {getFormattedDecimal} from "utils/numberUtil";
import HighLightedText from "../../HighLightedText";
import StatusChip from "../../StatusChip";
import "./index.css";

const RenderLink = (
  id,
  text,
  pageNo,
  cell,
  selectedCountry,
  siteReferenceId,
  divisionId,
) => {
  const {country} = useParams();
  return (
    <Link
      to={{
        pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${id}`,
        search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
      }}
      className="link-dark whitespace-nowrap"
    >
      <u>
        <HighLightedText
          value={text}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      </u>
    </Link>
  );
};

const RenderDropdown = ({
  certificateInboundEuId,
  selectedCountry,
  pageNo,
  siteReferenceId,
  divisionId,
}) => {
  const navigate = useNavigate();
  const {country} = useParams();

  return (
    <UncontrolledDropdown
      direction="down"
      className="doc-manager-action-dropdown"
    >
      <DropdownToggle
        color="standard-quartenary"
        className="!px-0 doc-manager-action-dropdown-button"
      >
        <Kebab24></Kebab24>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu" container="body">
        <DropdownItem
          onClick={() =>
            navigate({
              pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
                certificateInboundEuId,
              )}`,
              search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
            })
          }
        >
          View document
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

RenderDropdown.propTypes = {
  certificateInboundEuId: PropTypes.string,
  selectedCountry: PropTypes.string,
  pageNo: PropTypes.number,
  siteReferenceId: PropTypes.string,
  divisionId: PropTypes.string,
};

const renderStatus = (text, cell) => {
  const defaultClassName = docStatusClassNames["Failed to process"];
  const className = docStatusClassNames[text];

  return (
    <StatusChip
      className={className || defaultClassName}
      message={text}
      globalFilter={cell.getContext().table.getState().globalFilter}
    />
  );
};

const getColumnsPoland = (
  dateFormat,
  decimalFormatNew,
  selectedCountry,
  siteReferenceId,
  divisionId,
) => {
  return [
    {
      header: "",
      key: "expandCollapse",
      accessorKey: "splitDetails",
      visible: true,
      enableSorting: false,
      size: 20,
      cell: ({row}) => {
        return row.getCanExpand() ? (
          <Button
            onClick={row.getToggleExpandedHandler()}
            color="standard-quartenary"
            className="!px-0"
          >
            {row.getIsExpanded() ? <Up24 /> : <Down24 />}
          </Button>
        ) : (
          ""
        );
      },
    },
    {
      header: "Document",
      accessorKey: "sdNumber",
      key: "sdNumber",
      cell: ({row, getValue, table, cell}) => {
        return RenderLink(
          encodeURIComponent(row.original.certificateInboundEuId),
          getValue(),
          table.getState()?.pagination?.pageIndex,
          cell,
          selectedCountry,
          siteReferenceId,
          divisionId,
        );
      },
      size: 250,
      maxSize: 400,
      visible: true,
    },
    {
      header: "Supplier",
      accessorKey: "supplierName",
      key: "supplierName",
      size: 150,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Product",
      accessorKey: "productType",
      key: "productType",
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Raw material",
      accessorKey: "rawMaterialType",
      key: "rawMaterialType",
      size: 110,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Country of origin",
      accessorKey: "countryCode",
      key: "countryCode",
      size: 200,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity m³",
      accessorKey: "feedstockQty",
      key: "feedstockQty",
      size: 100,
      visible: true,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 3)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG savings %",
      accessorKey: "ghgEmissionPercentageIn",
      key: "ghgEmissionPercentageIn",
      size: 110,
      visible: true,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 2)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Issuance date",
      accessorKey: "dateOfIssuance",
      key: "dateOfIssuance",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Upload date",
      accessorKey: "bvAuditCreatedDatez",
      key: "bvAuditCreatedDatez",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Receiving point",
      accessorKey: "recipientReceiptAddress",
      key: "recipientReceiptAddress",
      size: 200,
      visible: false,
      cell: ({cell, row}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Additional information",
      accessorKey: "additionalInfo",
      key: "additionalInfo",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG total gCO2eq/MJ",
      accessorKey: "ghgTotal",
      key: "ghgTotal",
      size: 110,
      visible: false,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 3)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Scheme",
      accessorKey: "certificationSystem",
      key: "certificationSystem",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Dispatch address",
      accessorKey: "supplierDispatchAddress",
      key: "supplierDispatchAddress",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Dispatch date",
      accessorKey: "materialDispatchDatez",
      key: "materialDispatchDatez",
      size: 200,
      visible: false,
      filterFn: "dateRangeFilterFn",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Contract number",
      accessorKey: "contractNumber",
      key: "contractNumber",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    // This will be used later
    // {
    //   header: "Document type",
    //   accessorKey: "documentType",
    //   key: "documentType",
    //   size: 200,
    //   visible: false,
    //   cell: ({cell}) => {
    //     return (
    //       <HighLightedText
    //         value={cell.getValue()}
    //         globalFilter={cell.getContext().table.getState().globalFilter}
    //       />
    //     );
    //   },
    // },
    {
      header: "Reason for rejection",
      accessorKey: "rejectionReason",
      key: "rejectionReason",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Status",
      key: "state",
      disabled: true,
      accessorKey: "state",
      visible: true,
      draggaingDisabled: true,
      accessorFn: ({state}) => {
        const defaultMessage = docStatusMessages.failed_to_process;
        const message = docStatusMessages[state];
        return message || defaultMessage;
      },
      cell: ({getValue, cell}) => renderStatus(getValue(), cell),
    },
    {
      header: "",
      accessorKey: "actions",
      key: "actions",
      size: 25,
      visible: true,
      enableSorting: false,
      cell: ({
        row: {
          original: {certificateInboundEuId},
        },
        table,
      }) => (
        <RenderDropdown
          certificateInboundEuId={certificateInboundEuId}
          selectedCountry={selectedCountry}
          pageNo={table.getState()?.pagination?.pageIndex}
          siteReferenceId={siteReferenceId}
          divisionId={divisionId}
        />
      ),
    },
  ];
};

export default getColumnsPoland;
