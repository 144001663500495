const {DIV_CODE_SAF} = require("constants/divisionDetails");
const {getColumnsSAF} = require("./components/MandateAllocateTable/columnsSAF");

const getMandateAllocationColumns = (
  country,
  division,
  dateFormat,
  decimalFormat,
  siteReferenceId,
  divisionId,
) => {
  switch (division) {
    case DIV_CODE_SAF:
      return getColumnsSAF(
        dateFormat,
        decimalFormat,
        country,
        siteReferenceId,
        divisionId,
      );
    default:
      return getColumnsSAF(
        dateFormat,
        decimalFormat,
        country,
        siteReferenceId,
        divisionId,
      );
  }
};

export default getMandateAllocationColumns;
