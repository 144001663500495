import {useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {Filter24} from "@bphxd/ds-core-react/lib/icons";
import {OUTGOING_DOCS} from "graphql/docManager/outgoingDocuments";
import {SendCertificateEmailAPI} from "graphql/docManager/sendCertificateDetails";
import {startCase} from "lodash";
import {useAppSetting} from "providers/appSetting/context.js";
import {useUserSettings} from "providers/userSettings";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Button} from "reactstrap";
import {
  getDivisionDataByCountry,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import CustomizeTable from "../CustomizeTable/CustomizeTable";
import Layout from "../Layout";
import DocumentTable from "../Table/DocumentTableWithFilter";

import getColumns from "./Columns/ColumnsSpain";

const OutgoingDocumentPage = () => {
  const {appSetting, setAppSetting} = useAppSetting();
  const {country: countryName} = useParams();
  setCountryDetails(countryName);
  const countryId = appSetting?.currentCountryMappingData?.countryId;

  const siteReferenceData = getSiteDetails(countryId);

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const [searchParams] = useSearchParams();
  const pageIndexNumber = searchParams.get("pageNo") ?? 0;

  const [highlightedRows, setHighlightedRows] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [filtering, setFiltering] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [pageIndex, setPageIndex] = useState(pageIndexNumber);

  const divisionData = useMemo(() => {
    if (appSetting?.divisionData) {
      return getDivisionDataByCountry(countryName);
    }
    return null;
  }, [countryName, appSetting?.divisionData]);

  const handleSendCertificate = useCallback(
    async (certificateOutboundEuId, mbOutboundQtyEuId) => {
      await SendCertificateEmailAPI({
        event: {
          userId: account?.username,
          fileData: [{certificateOutboundEuId, mbOutboundQtyEuId}],
          siteReferenceId: siteReferenceData?.siteReferenceId,
          divisionId: divisionData?.divisionId,
        },
      });
      setHighlightedRows([certificateOutboundEuId]);
      setRowSelection({});
      toast.success("Certificate sent");
    },
    [
      account?.username,
      divisionData?.divisionId,
      siteReferenceData?.siteReferenceId,
    ],
  );

  const tableCols = useMemo(
    () =>
      getColumns(
        dateFormat,
        decimalFormat,
        handleSendCertificate,
        countryName,
        divisionData?.divisionCode,
      ),
    [
      dateFormat,
      decimalFormat,
      handleSendCertificate,
      countryName,
      divisionData,
    ],
  );

  const [columns, setColumns] = useState(tableCols);

  useEffect(() => {
    if (tableCols) {
      setColumns(tableCols);
    }
  }, [tableCols, dateFormat]);

  const filteredColumns = useMemo(
    () => columns.filter(({visible}) => visible),
    [columns],
  );

  const {data, loading} = useQuery(OUTGOING_DOCS, {
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      sdNumber: "",
      certificateOutboundEuId: "",
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,

    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const tableData = loading
    ? []
    : data?.bioLcGetCertificateOutboundData?.documents;

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: "Outgoing documents", link: ""},
  ];

  return (
    <Layout title="Outgoing documents" breadcrumbs={breadcrumbItems}>
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className=" bg-white flex -flex-row justify-between w-full px-4 py-5">
          <CustomizeTable
            columns={columns}
            showApplyButton={false}
            onColumnChange={(cols) => setColumns(cols)}
          />
          <Button
            data-test="doc-manager-filter-btn"
            color="standard-tertiary rounded-0"
            onClick={() => setShowFilter(!showFilter)}
          >
            Filters
            <Filter24 className="btn-icon-suffix" />
          </Button>
        </div>
        <div className="border-secondary border-top w-full">
          <DocumentTable
            columns={filteredColumns}
            data={tableData ?? []}
            className="outgoing-docs-table-docmanager"
            highlightedRows={highlightedRows}
            loading={loading}
            filtering={filtering?.trim()}
            setFiltering={setFiltering}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
            type="Outgoing"
            enableRowSelection
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            pageIndex={pageIndex}
            showFilter={showFilter}
          />
        </div>
        <div>
          <Button
            className="rounded-0 mb-40"
            type=""
            onClick={async () => {
              const selectedRecords = Object.keys(rowSelection)
                .map((index) => Number(index))
                .map((index) => tableData[index]);

              const selectedIds = selectedRecords.map(
                ({certificateOutboundEuId, mbOutboundQtyEuId}) => {
                  return {certificateOutboundEuId, mbOutboundQtyEuId};
                },
              );

              await SendCertificateEmailAPI({
                event: {
                  userId: account?.username,
                  fileData: selectedIds,
                  siteReferenceId: siteReferenceData?.siteReferenceId,
                  divisionId: divisionData?.divisionId,
                },
              });
              setHighlightedRows(
                selectedIds.map(
                  ({certificateOutboundEuId}) => certificateOutboundEuId,
                ),
              );
              setRowSelection({});
              toast.success("Certificates sent");
            }}
          >
            Send certificate
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default OutgoingDocumentPage;
