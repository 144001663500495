import {Add16} from "@bphxd/ds-core-react/lib/icons";
import {COUNTRY_SPAIN} from "constants/countryDetails";
import {DIV_CODE_COPRO, DIV_CODE_SAF} from "constants/divisionDetails";
import process from "process";
import PropTypes from "prop-types";
import {
  convertToNumber,
  formatNumber,
  useUserSettings,
} from "providers/userSettings";
import {useEffect, useMemo, useState} from "react";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {Button, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {
  getDDVNumberInputPattern,
  getDDVTDVNumberInputPattern,
  getNumberInputPattern,
  handleNumber,
  handleNumberAndDDV,
  handleNumberDDVAndTDV,
} from "utils/numberUtil";
import CheckboxFlag from "../CheckboxNullable";
import Datepicker from "../Datepicker";
import GHGSplit from "./GHGSplit";
import {DDV, TDV, parseFormattedGhgValues} from "./utils";

const GHGEmissionInfo = ({
  countries,
  status,
  selectedCountry,
  isDisabled,
  divisionCode,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  const [isCheckedYes, setIsCheckedYes] = useState(null);
  const hideLtpButton =
    process.env.REACT_APP_COPRO_LINK_TO_PURCHASE_HIDE_VISIBILITY;

  const {
    register,
    formState: {errors},
    setError,
    control,
    watch,
    clearErrors,
    setValue,
    getValues,
  } = useFormContext();
  const {fields, append, remove} = useFieldArray({
    control,
    name: "splitDetails",
    mode: "onBlur",
    rules: {
      validate: (data) => {
        data?.forEach((item, index) => {
          if (
            item.ghgTotal === TDV ||
            item.ghgTotal === DDV ||
            item.defaultValueAppliedFlag
          ) {
            clearErrors(`splitDetails.${index}.ghgEec`);
            clearErrors("ghgNoValue");
            clearErrors("ghgTotal");
          } else if (
            !isDisabled &&
            item.defaultValueAppliedFlag !== true &&
            parseFloat(convertToNumber(item.ghgEccr ?? "0", decimalFormat)) ===
              0 &&
            parseFloat(convertToNumber(item.ghgEccs ?? "0", decimalFormat)) ===
              0 &&
            parseFloat(convertToNumber(item.ghgEec ?? "0", decimalFormat)) ===
              0 &&
            parseFloat(convertToNumber(item.ghgEee ?? "0", decimalFormat)) ===
              0 &&
            parseFloat(convertToNumber(item.ghgEl ?? "0", decimalFormat)) ===
              0 &&
            parseFloat(convertToNumber(item.ghgEp ?? "0", decimalFormat)) ===
              0 &&
            parseFloat(convertToNumber(item.ghgEsca ?? "0", decimalFormat)) ===
              0 &&
            parseFloat(convertToNumber(item.ghgEtd ?? "0", decimalFormat)) ===
              0 &&
            parseFloat(convertToNumber(item.ghgEu ?? "0", decimalFormat)) ===
              0 &&
            (item.ghgTotal !== TDV ||
              item.ghgTotal !== DDV ||
              item.ghgEtd !== DDV)
          ) {
            setError(`splitDetails.${index}.ghgEec`, {
              type: "value",
              message: `At least one GHG emission must be greater than 0 ${item.defaultValueAppliedFlag}`,
            });
          } else {
            clearErrors(`splitDetails.${index}.ghgEec`);
          }
        });
      },
    },
  });
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };
  const createAdditionalSplit = () => {
    clearErrors(["physicalReceiptDate", "rawMaterialOriginCountryName"]);
    setIsCheckedYes(null);
    if (fields.length === 0) {
      const values = getValues();
      append({
        ghgEec: values.ghgEec ?? 0.0,
        ghgEl: values.ghgEl ?? 0.0,
        ghgEp: values.ghgEp ?? 0.0,
        ghgEtd: values.ghgEtd ?? 0.0,
        ghgEu: values.ghgEu ?? 0.0,
        ghgEsca: values.ghgEsca ?? 0.0,
        ghgEccs: values.ghgEccs ?? 0.0,
        ghgEccr: values.ghgEccr ?? 0.0,
        ghgTotal: values.ghgTotal ?? 0.0,
        physicalReceiptDate: values.physicalReceiptDate ?? "",
        originCountryName: values.rawMaterialOriginCountryName ?? "",
        defaultValueAppliedFlag: values.defaultValueAppliedFlag ?? null,
      });
    }
    append({
      ghgEec: 0.0,
      ghgEl: 0.0,
      ghgEp: 0.0,
      ghgEtd: 0.0,
      ghgEu: 0.0,
      ghgEsca: 0.0,
      ghgEccs: 0.0,
      ghgEccr: 0.0,
      ghgTotal: 0.0,
      physicalReceiptDate: "",
      originCountryName: "",
      defaultValueAppliedFlag: null,
    });
  };
  const splitDetailsField = watch("splitDetails");
  const changes = useWatch({
    name: [
      "ghgEec",
      "ghgEl",
      "ghgEp",
      "ghgEtd",
      "ghgEu",
      "ghgEsca",
      "ghgEccs",
      "ghgEccr",
      "defaultValueAppliedFlag",
    ],
  });

  const numberInputPattern = useMemo(
    () => getNumberInputPattern(decimalFormat),
    [decimalFormat],
  );

  const ddvNumberInputPattern = useMemo(
    () => getDDVNumberInputPattern(decimalFormat),
    [decimalFormat],
  );

  const tdvddvNumberInputPattern = useMemo(
    () => getDDVTDVNumberInputPattern(decimalFormat),
    [decimalFormat],
  );

  useEffect(() => {
    const ghgTotal = getValues("ghgTotal");
    const flagValue = getValues("defaultValueAppliedFlag");
    const parsedChanges = parseFormattedGhgValues(changes, decimalFormat);
    const total =
      parsedChanges[0] +
      parsedChanges[1] +
      parsedChanges[2] +
      parsedChanges[3] +
      parsedChanges[4] -
      (parsedChanges[5] + parsedChanges[6] + parsedChanges[7]);

    if (ghgTotal !== TDV && ghgTotal !== DDV) {
      setValue(
        "ghgTotal",
        formatNumber(parseFloat(total.toFixed(3) ?? 0), decimalFormat, 0),
      );
      setIsCheckedYes(false);
    }
    if (flagValue) {
      setValue("ghgTotal", TDV);
      setIsCheckedYes(true);
    }
    if (!flagValue) {
      setValue(
        "ghgTotal",
        formatNumber(parseFloat(total.toFixed(3) ?? 0), decimalFormat, 0),
      );
      setIsCheckedYes(false);
    }
  }, [changes, setValue, getValues, isCheckedYes, clearErrors, decimalFormat]);

  return (
    <div className="flex flex-col gap-4 pb-6">
      {fields?.length === 0 && (
        <>
          <FormGroup>
            <Label for="defaultValueAppliedFlag" className="fw-normal">
              Total default value according to RED II applied
            </Label>
            <CheckboxFlag
              id="defaultValueAppliedFlag"
              name="defaultValueAppliedFlag"
              control={control}
              error={errors.defaultValueAppliedFlag}
              disabled={isDisabled}
            />
          </FormGroup>
          {!isCheckedYes && (
            <>
              <div className="flex flex-row justify-between items-start gap-3 overflow-x-scroll w-full">
                <FormGroup>
                  <Label for="ghgEec" className="fw-normal">
                    Eec
                  </Label>
                  <Input
                    type="text"
                    id="ghgEec"
                    data-test="ghgEec"
                    {...computeProps("ghgEec", numberInputPattern)}
                    invalid={!!errors.ghgEec && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEec && (
                    <FormFeedback>{errors.ghgEec?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEl" className="fw-normal">
                    + El
                  </Label>
                  <Input
                    type="text"
                    id="ghgEl"
                    data-test="ghgEl"
                    {...computeProps("ghgEl", numberInputPattern)}
                    invalid={!!errors.ghgEl && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEl && (
                    <FormFeedback>{errors.ghgEl?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEp" className="fw-normal">
                    + Ep
                  </Label>
                  <Input
                    type="text"
                    id="ghgEp"
                    data-test="ghgEp"
                    {...computeProps("ghgEp", numberInputPattern)}
                    invalid={!!errors.ghgEp && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEp && (
                    <FormFeedback>{errors.ghgEp?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEtd" className="fw-normal">
                    + Etd
                  </Label>
                  <Input
                    type="text"
                    id="ghgEtd"
                    data-test="ghgEtd"
                    {...computeProps("ghgEtd", ddvNumberInputPattern)}
                    invalid={!!errors.ghgEtd && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumberAndDDV(e)}
                    className="!w-[93px]"
                  />
                  {errors.ghgEtd && (
                    <FormFeedback>{errors.ghgEtd?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEu" className="fw-normal">
                    + Eu
                  </Label>
                  <Input
                    type="text"
                    id="ghgEu"
                    data-test="ghgEu"
                    {...computeProps("ghgEu", numberInputPattern)}
                    invalid={!!errors.ghgEu && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEu && (
                    <FormFeedback>{errors.ghgEu?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEsca" className="fw-normal">
                    - Esca
                  </Label>
                  <Input
                    type="text"
                    id="ghgEsca"
                    data-test="ghgEsca"
                    {...computeProps("ghgEsca", numberInputPattern)}
                    invalid={!!errors.ghgEsca && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEsca && (
                    <FormFeedback>{errors.ghgEsca?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEccs" className="fw-normal">
                    - Eccs
                  </Label>
                  <Input
                    type="text"
                    id="ghgEccs"
                    data-test="ghgEccs"
                    {...computeProps("ghgEccs", numberInputPattern)}
                    invalid={!!errors.ghgEccs && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEccs && (
                    <FormFeedback>{errors.ghgEccs?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEccr" className="fw-normal">
                    - Eccr
                  </Label>
                  <Input
                    type="text"
                    id="ghgEccr"
                    data-test="ghgEccr"
                    {...computeProps("ghgEccr", numberInputPattern)}
                    invalid={!!errors.ghgEccr && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEccr && (
                    <FormFeedback>{errors.ghgEccr?.message}</FormFeedback>
                  )}
                </FormGroup>
              </div>
              {errors.ghgNoValue && (
                <FormFeedback className="!mt-[-15px] !block">
                  {errors.ghgNoValue.message}
                </FormFeedback>
              )}
            </>
          )}
          <FormGroup>
            <Label for="ghgTotal" className="fw-normal">
              GHG (total){" "}
              {divisionCode === DIV_CODE_COPRO ? "kgCO2/dry-t" : "gCO2eq/MJ"}
            </Label>
            <Input
              type="text"
              id="ghgTotal"
              data-test="ghgTotal"
              {...computeProps("ghgTotal", {
                required: "Please enter total GHG emissions",
                ...tdvddvNumberInputPattern,
              })}
              invalid={!!errors.ghgTotal && !isDisabled}
              disabled={isDisabled || isCheckedYes}
              onKeyDown={(e) => handleNumberDDVAndTDV(e)}
              maxLength={20}
            />
            {errors.ghgTotal && (
              <FormFeedback>{errors.ghgTotal?.message}</FormFeedback>
            )}
          </FormGroup>
          {divisionCode === DIV_CODE_SAF && (
            <FormGroup>
              <Label for="ghgEmissionPercentageIn" className="fw-normal mb-4">
                GHG emission saving (%)
              </Label>
              <Input
                type="text"
                id="ghgEmissionPercentageIn"
                data-test="ghgEmissionPercentageIn"
                {...computeProps("ghgEmissionPercentageIn", {
                  required: "Please enter GHG emission savings",
                  ...tdvddvNumberInputPattern,
                })}
                invalid={!!errors.ghgEmissionPercentageIn}
                disabled={isDisabled}
                onKeyDown={(e) => handleNumberDDVAndTDV(e)}
                maxLength={20}
              />
              <span className="text-xs text-gray-700">
                Biofuels for transport
              </span>
              {errors.ghgEmissionPercentageIn && (
                <FormFeedback className="mt-2">
                  {errors.ghgEmissionPercentageIn?.message}
                </FormFeedback>
              )}
            </FormGroup>
          )}

          {selectedCountry === COUNTRY_SPAIN && hideLtpButton !== "false" && (
            <FormGroup>
              <Label for="physicalReceiptDate" className="fw-normal">
                Date of physical receipt
              </Label>
              <Datepicker
                id="physicalReceiptDate"
                name="physicalReceiptDate"
                control={control}
                error={errors.physicalReceiptDate}
                placeholder="Please add physical receipt date"
                disabled={isDisabled || (splitDetailsField?.length ?? 0) > 0}
                rules={{
                  required: "Please add physical receipt date",
                }}
              />
              {errors.physicalReceiptDate &&
                (splitDetailsField?.length ?? 0) === 0 && (
                  <FormFeedback>
                    {errors.physicalReceiptDate?.message}
                  </FormFeedback>
                )}
            </FormGroup>
          )}
        </>
      )}
      {fields.map((field, index) => (
        <div key={field.id}>
          <GHGSplit
            field={field}
            index={index}
            countries={countries}
            onRemoveSplit={remove}
            status={status}
            isCheckedYes={isCheckedYes}
            isDisabled={isDisabled}
          />
          <hr className="border-1 mt-4"></hr>
        </div>
      ))}
      {!isDisabled && fields?.length === 0 && <hr className="border-1"></hr>}
      {!isDisabled && selectedCountry === COUNTRY_SPAIN && (
        <div>
          <Button
            size="xs"
            onClick={createAdditionalSplit}
            color="standard-primary"
            className="primary-btn rounded-0"
          >
            Create split
            <Add16 className="btn-icon-suffix" />
          </Button>
        </div>
      )}
      {selectedCountry === COUNTRY_SPAIN && (
        <>
          <FormGroup className="flex flex-col">
            <Label for="disaggregatedDefaultValueOilFlag" className="fw-normal">
              Disaggregated default value for oil extraction only is applied
            </Label>
            <FormGroup
              check
              inline
              className="form-check-alt form-check-lg !mb-0"
            >
              <Input
                type="checkbox"
                id="disaggregatedDefaultValueOilFlag"
                data-test="disaggregatedDefaultValueOilFlag"
                {...computeProps("disaggregatedDefaultValueOilFlag")}
                invalid={
                  !!errors.disaggregatedDefaultValueOilFlag && !isDisabled
                }
                disabled={isDisabled}
              />
              <Label for="disaggregatedDefaultValueOilFlag">Yes</Label>
            </FormGroup>
          </FormGroup>
          <FormGroup className="flex flex-col">
            <Label
              for="disaggregatedDefaultValueSoiln2oFlag"
              className="fw-normal"
            >
              Disaggregated default value for soil N2O emissions is applied
            </Label>
            <FormGroup
              check
              inline
              className="form-check-alt form-check-lg !mb-0"
            >
              <Input
                type="checkbox"
                id="disaggregatedDefaultValueSoiln2oFlag"
                data-test="disaggregatedDefaultValueSoiln2oFlag"
                {...computeProps("disaggregatedDefaultValueSoiln2oFlag")}
                invalid={
                  !!errors.disaggregatedDefaultValueSoiln2oFlag && !isDisabled
                }
                disabled={isDisabled}
              />
              <Label for="disaggregatedDefaultValueSoiln2oFlag">Yes</Label>
            </FormGroup>
          </FormGroup>
          <FormGroup>
            <Label for="bonusCo245gManureUsedFlag" className="fw-normal">
              Esca: Bonus of 45g CO2eq/MJ manure in the case animal manure is
              used as substrate for the production of biogas and biomethane
            </Label>
            <CheckboxFlag
              id="bonusCo245gManureUsedFlag"
              name="bonusCo245gManureUsedFlag"
              control={control}
              error={errors.bonusCo245gManureUsedFlag}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup>
            <Label for="bonusCo229gManureUsedFlag" className="fw-normal">
              El: Bonus of 29 g CO2eq/MJ biofuel/bioliquid/biomass fuel if
              biomass is obtained from restored degraded land
            </Label>
            <CheckboxFlag
              id="bonusCo229gManureUsedFlag"
              name="bonusCo229gManureUsedFlag"
              control={control}
              error={errors.bonusCo229gManureUsedFlag}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup>
            <Label for="supplychainIncentiveReceivedFlag" className="fw-normal">
              For biogas supply chains: Were incentives/subsidies received for
              the production of the biogas?
            </Label>
            <CheckboxFlag
              id="supplychainIncentiveReceivedFlag"
              name="supplychainIncentiveReceivedFlag"
              control={control}
              error={errors.supplychainIncentiveReceivedFlag}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup>
            <Label for="supplychainIncentiveReceivedName" className="fw-normal">
              If yes please specify
            </Label>
            <Input
              type="text"
              id="supplychainIncentiveReceivedName"
              data-test="supplychainIncentiveReceivedName"
              {...computeProps("supplychainIncentiveReceivedName")}
              invalid={!!errors.supplychainIncentiveReceivedName && !isDisabled}
              disabled={isDisabled}
              maxLength={100}
            />
            {errors.supplychainIncentiveReceivedName && (
              <FormFeedback>
                {errors.supplychainIncentiveReceivedName.message}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="nuts2Region" className="fw-normal">
              If applicable, please specify the NUTS2 Region
            </Label>
            <Input
              type="text"
              id="nuts2Region"
              data-test="nuts2Region"
              {...computeProps("nuts2Region")}
              invalid={!!errors.nuts2Region && !isDisabled}
              disabled={isDisabled}
              maxLength={100}
            />
            {errors.nuts2Region && (
              <FormFeedback>{errors.nuts2Region.message}</FormFeedback>
            )}
          </FormGroup>
        </>
      )}
      {selectedCountry !== COUNTRY_SPAIN && divisionCode !== DIV_CODE_SAF && (
        <>
          <FormGroup>
            <Label
              for="producerInstallationDateFlag"
              className="fw-normal mb-4"
            >
              The installation where the final biofuel or bioliquid was produced
              started physical production of biofuels or bioliquids after 5
              October 2015
            </Label>
            <CheckboxFlag
              id="producerInstallationDateFlag"
              name="producerInstallationDateFlag"
              control={control}
              error={errors.producerInstallationDateFlag}
              disabled={isDisabled}
              rules={{
                validate: (value) => {
                  return value !== null || "Please select an option";
                },
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label for="incentiveFlag" className="fw-normal mb-4">
              Were incentives/subsidies received for the production of the
              biogas?
            </Label>
            <CheckboxFlag
              id="incentiveFlag"
              name="incentiveFlag"
              control={control}
              error={errors.incentiveFlag}
              disabled={isDisabled}
              rules={{
                validate: (value) => {
                  return value !== null || "Please select an option";
                },
              }}
            />
          </FormGroup>
        </>
      )}
    </div>
  );
};
GHGEmissionInfo.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  status: PropTypes.string.isRequired,
  selectedCountry: PropTypes.string,
  isDisabled: PropTypes.bool,
  divisionCode: PropTypes.string,
};
export default GHGEmissionInfo;
