import {Datepicker, Timepicker} from "@bphxd/ds-core-react";
import {
  BTU_ID_PREFIX,
  dtnShipmentKeys,
  VOLUME_PER_HOUR,
} from "modules/co-processing/constants/shipments";
import {convertPSTtoUTC} from "modules/co-processing/helpers/formBuilder";
import InputNumeric from "modules/common/lnputNumeric";
import moment from "moment-timezone";
import React, {useRef} from "react";
import {Input, InputGroup, InputGroupText} from "reactstrap";
import dateFns from "utils/helpers/dateFunctions";
import parseAndRoundToTwoDecimal from "utils/numberUtil";

export const formatDatePicker = (date) =>
  date ? dateFns.convertDateFormat_MM_DD_YYYY(date) : "-";

export const formatTimePicker = (time) => {
  if (!time) return "-";

  const formatted = moment(time, "HH:mm").format("hh:mm A");

  return formatted;
};

export const formatDateTimePicker = (datetime) => {
  if (!datetime) return "-";

  const formatted = moment(datetime, "YYYY-MM-DD hh:mm A").format(
    "MM/DD/YYYY hh:mm A",
  );

  return formatted;
};

export function calculateMaxVolume(startTime, endTime) {
  if (!startTime || !endTime) {
    return null;
  }
  const format = "YYYY-MM-DD hh:mm A";
  const startMoment = moment(startTime, format);
  const endMoment = moment(endTime, format);

  const diffHours = endMoment.diff(startMoment, "hours", true);
  const estimatedVolume = diffHours * VOLUME_PER_HOUR;
  return parseAndRoundToTwoDecimal(estimatedVolume);
}

export function displayFieldInfo(fieldName, fieldValue, fieldConfig) {
  if (fieldName === dtnShipmentKeys.RENEWABLE_SHIPMENT_FLAG) {
    return fieldValue === false;
  }
  return !!fieldConfig.info;
}

// Combines date and time into a single string
const formatShipmentDateTime = (date, time) => {
  const isValidDate = moment(date, "YYYY-MM-DD", true).isValid();
  const isValidTime = moment(time, "HH:mm", true).isValid();

  if (isValidDate && isValidTime) {
    return `${date} ${time}`;
  }

  return date;
};

const parseVolume = (volume = "") => {
  const parsedVolume = parseFloat(volume.replace(/,/g, ""));

  return Number.isNaN(parsedVolume) ? 0 : parsedVolume;
};

export function formatTruckRack(formData) {
  if (!formData) {
    return null;
  }

  const {shipment_datez, shipment_start_datez, shipment_end_datez, ...rest} =
    formData;

  const pstShipmentDate = new Date(
    new Date(shipment_datez).getTime() +
      new Date().getTimezoneOffset() * 1000 * 60,
  );

  return {
    ...rest,
    shipment_datez: convertPSTtoUTC(pstShipmentDate),
    shipment_start_datez: convertPSTtoUTC(shipment_start_datez),
    shipment_end_datez: convertPSTtoUTC(shipment_end_datez),
  };
}

const inputMaxLength = (isBtu, field) => {
  if (field.name === dtnShipmentKeys.SHIPMENT_ID) {
    return isBtu ? 23 : 32;
  }
  if (field.name === dtnShipmentKeys.SOURCE_TANK) {
    return 10;
  }
  return 250;
};

export const renderInputs = {
  input: ({addOn, error, field, placeholder, type, onBlur, prevId, isBtu}) => {
    return (
      <>
        <InputGroup className="bg-white">
          {isBtu && (
            <div data-test="btu-prepended" className="input-group-prepend">
              <div className="input-group-text">{BTU_ID_PREFIX}</div>
            </div>
          )}
          <Input
            {...field}
            invalid={!!error}
            placeholder={placeholder}
            type={type}
            onBlur={onBlur}
            maxLength={inputMaxLength(isBtu, field)}
          />
          {addOn && (
            <InputGroupText className="bg-transparent">{addOn}</InputGroupText>
          )}
        </InputGroup>
        {prevId && (
          <span className="flex justify-end items-baseline text-[12px] font-[400] mt-1 text-[#111111] text-opacity-[0.64]">
            {`Previous: ${prevId}`}
          </span>
        )}
      </>
    );
  },

  date: ({error, field}) => {
    return (
      <Datepicker
        name={field.name}
        required
        className="createShipment__datePicker"
        invalid={!!error}
        options={{
          altFormat: "m/j/Y",
          dateFormat: "Y-m-d",
          defaultDate: field?.value,
          onChange: (_, dateStr) => field?.onChange(dateStr),
        }}
      />
    );
  },

  datetime: ({field, error, startTime}) => {
    const datepickerRef = useRef(null);

    const handleDateTimeChange = (selectedDates, dateStr, instance) => {
      // Check if user is interacting with time picker
      const flatpickrActiveClasses =
        datepickerRef.current?.flatpickr?.element?.classList;
      const timepickerActiveClassList = [
        "flatpickr-hour",
        "flatpickr-time",
        "flatpickr-am-pm",
      ];

      const isTimePickerActive = timepickerActiveClassList.some((className) =>
        flatpickrActiveClasses?.contains(className),
      );

      if (isTimePickerActive) {
        instance.open(); // Prevent calendar dropdown from closing
      }
      field?.onChange(dateStr);
    };

    return (
      <Datepicker
        name={field.name}
        required
        className="createShipment__datePicker"
        invalid={!!error}
        options={{
          allowInput: false,
          altFormat: "m/j/Y h:i K",
          dateFormat: "Y-m-d H:i",
          enableTime: true,
          time_24hr: true,
          minuteIncrement: 5,
          minDate:
            field.name === dtnShipmentKeys.SHIPMENT_END_DATE
              ? startTime
              : undefined,
          defaultDate: field?.value,
          onClose: handleDateTimeChange,
        }}
      />
    );
  },

  time: ({field, error, startTime}) => {
    const options = {
      altInput: true,
      enableTime: true,
      noCalendar: true,
      altFormat: "h:i K", // Display format for user
      defaultDate: field?.value,
      dateFormat: "H:i",
      minTime:
        field.name === dtnShipmentKeys.SHIPMENT_END_DATE
          ? startTime
          : undefined,
      onChange: (selectedDates, dateStr) => {
        field.onChange(dateStr);
      },
    };

    return (
      <Timepicker
        required
        id={field?.name}
        name={field?.name}
        invalid={!!error}
        options={options}
        className="createShipment__timePicker form-control-prepended"
      />
    );
  },

  select: ({field, error, options, placeholder = "Select an option"}) => {
    return (
      <Input type="select" {...field} invalid={!!error}>
        <option key="none" disabled hidden value="">
          {`-- ${placeholder} --`}
        </option>

        {options?.map((option) => (
          <option key={option?.value || option} value={option?.value || option}>
            {option?.label || option}
          </option>
        ))}
      </Input>
    );
  },

  number: ({field, error, addOn, ...config}) => {
    return (
      <InputGroup className="bg-white">
        <InputNumeric
          {...field}
          className="form-control"
          decimals={2}
          placeholder={config?.placeholder}
          thousandSeparator
        />
        {addOn && (
          <InputGroupText className="bg-transparent">{addOn}</InputGroupText>
        )}
      </InputGroup>
    );
  },
};

const shipmentDateFields = [
  "shipment_datez",
  "shipment_start_datez",
  "shipment_end_datez",
];

export const formatBackToUnit = (formData) => {
  console.log("Input formData:", formData);

  const shipmentDetails = {...formData};

  shipmentDateFields.forEach((dateField) => {
    shipmentDetails[dateField] = convertPSTtoUTC(formData?.[dateField]);
  });

  shipmentDetails.dtn_shipment_id = BTU_ID_PREFIX + formData.dtn_shipment_id;
  shipmentDetails.estimated_volume = parseVolume(formData.estimated_volume);

  console.log("Formatted result:", shipmentDetails);

  return shipmentDetails;
};

export const formatBackToUnit_ = (formData) => {
  const {
    shipment_datez,
    shipment_start_datez,
    shipment_end_datez,
    ...shipmentDetails
  } = formData;

  const shipmentDate =
    formatShipmentDateTime(shipment_datez?.date, shipment_datez?.time) || null;

  return {
    ...shipmentDetails,
    shipment_datez: convertPSTtoUTC(shipmentDate),
    dtn_shipment_id: BTU_ID_PREFIX + formData.dtn_shipment_id,
    shipment_start_datez: convertPSTtoUTC(shipment_start_datez),
    shipment_end_datez: convertPSTtoUTC(shipment_end_datez),
    estimated_volume: parseVolume(formData?.estimated_volume),
  };
};

export const formatInventoryTransfer = (formData) => {
  const {
    shipment_start_datez,
    shipment_end_datez,
    adjusted_volume,
    source_resource_batch_id,
    destination_resource_batch_id,
  } = formData;

  return {
    destination_batch_id: destination_resource_batch_id,
    batch_id: source_resource_batch_id,
    shipment_start_datez: convertPSTtoUTC(shipment_start_datez),
    shipment_end_datez: convertPSTtoUTC(shipment_end_datez),
    adjusted_volume_bbl: parseVolume(adjusted_volume),
  };
};
