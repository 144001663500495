export const docStatus = {
  ready_to_assign: {
    message: "Ready to assign",
    className: " disabled:text-white bg-[#ECFAFE]",
  },
  requires_attention: {
    message: "Requires attention",
    className: "disabled:text-white bg-[#FFEDF0]",
  },
  ready_to_accept: {
    message: "Ready to accept",
    className: "disabled:text-white bg-[#FFFFF0]",
  },
  rejected: {
    message: "Rejected",
    className: "disabled:text-white bg-[#ededed]",
  },
  failed_to_process: {
    message: "Rejected",
    className: "disabled:text-white bg-[#e6fff2]",
  },
  assigned: {
    message: "Completed",
    className: "disabled:text-white bg-[#E6FFF2]",
  },
  additional_info: {
    message: "Additional info",
    className: "disabled:text-white bg-[#e6fff2]",
  },
  attention: {
    message: "Attention",
    className: "disabled:text-white bg-[#e6fff2]",
  },
  completed: {
    message: "Completed",
    className: "disabled:text-white bg-[#E6FFF2]",
  },
  split: {
    message: "Split",
    className: "disabled:text-white bg-[#e1f9fa]",
  },
  not_available: {
    message: "Not available status",
    className: "disabled:text-white bg-[#FFEDF0]",
  },
};

export const docStatusMessages = {
  ready_to_assign: "Ready to assign",
  requires_attention: "Requires attention",
  ready_to_accept: "Ready to accept",
  rejected: "Rejected",
  failed_to_process: "Failed to process",
  assigned: "Completed",
  additional_info: "Additional info",
  attention: "Attention",
  completed: "Completed",
  split: "Split",
  not_available: "Not available status",
};

const {
  ready_to_accept,
  requires_attention,
  ready_to_assign,
  rejected,
  failed_to_process,
  assigned,
  additional_info,
  attention,
  completed,
  split,
  not_available,
} = docStatusMessages;

export const docStatusClassNames = {
  [ready_to_accept]: "disabled:text-white bg-[#FFFFF0]",
  [requires_attention]: "disabled:text-white bg-[#FFEDF0]",
  [ready_to_assign]: "disabled:text-white bg-[#ECFAFE]",
  [rejected]: "disabled:text-white bg-[#ededed]",
  [failed_to_process]: "disabled:text-white bg-[#e6fff2]",
  [assigned]: "disabled:text-white bg-[#E6FFF2]",
  [additional_info]: "disabled:text-white bg-[#e6fff2]",
  [attention]: "disabled:text-white bg-[#e6fff2]",
  [completed]: "disabled:text-white bg-[#E6FFF2]",
  [split]: "disabled:text-white bg-[#e1f9fa]",
  [not_available]: "disabled:text-white bg-[#FFEDF0]",
};

export default {docStatus};
