import {gql} from "@apollo/client";

export const MANDATE_CERTIFICATES_API = gql`
  query bioLcGetMandateCertificateAPI(
    $siteReferenceId: String
    $divisionId: String
    $mbLocationGroupId: String
    $outMovementType: String
    $period: String
  ) {
    bioLcGetMandateCertificateAPI(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbLocationGroupId: $mbLocationGroupId
        outMovementType: $outMovementType
        period: $period
      }
    ) {
      mandateCertificateData {
        inboundRecords {
          actualizedQty
          documentName
          ghgTotal
          originCountryName
          physicalReceiptDate
          rawMaterial
          retiredQty
        }
        outboundRecord {
          quantityOutM3
          documentName
          outboundType
          recipient
          status
        }
      }
      error
      statusCode
    }
  }
`;

export default MANDATE_CERTIFICATES_API;
