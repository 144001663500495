import {
  dtnShipmentKeys,
  INPUT_METRIC,
  SHIPMENTS_LABELS,
  SHIPMENT_SUMMARY_LABELS,
  SHIPMENT_TYPE,
} from "modules/co-processing/constants/shipments";
import {formatDateTimePicker, formatDatePicker} from "./formUtils";

const FIRST_STEP = 0;

const InitialShipmentState = {[dtnShipmentKeys.SHIPMENT_TYPE]: ""};

export const shipmentTypeToUpperCase = (value) => {
  if (!value) {
    return "-";
  }
  const displayVal = value.replace(/_/g, " ").toLowerCase();
  return displayVal.charAt(0).toUpperCase() + displayVal.slice(1);
};

const TruckRackFields = {
  SHIPMENT_ID: {
    key: dtnShipmentKeys.SHIPMENT_ID,
    label: "Shipment ID",
  },
  SHIPMENT_TYPE: {
    key: dtnShipmentKeys.SHIPMENT_TYPE,
    label: SHIPMENTS_LABELS.SHIPMENT_TYPE,
    options: [
      {value: SHIPMENT_TYPE.BACK_TO_UNIT, label: "Back to unit misc"},
      {value: SHIPMENT_TYPE.TRUCK_RACK, label: "Truck rack"},
      {value: SHIPMENT_TYPE.INVENTORY_TRANSFER, label: "Inventory Transfer"},
    ],
    format: shipmentTypeToUpperCase,
    type: "select",
  },
  SHIPMENT_DATE: {
    key: dtnShipmentKeys.SHIPMENT_DATE,
    label: SHIPMENTS_LABELS.SHIPMENT_DATE,
    format: formatDatePicker,
    type: "date",
  },
  SHIPMENT_START_DATE: {
    key: dtnShipmentKeys.SHIPMENT_START_DATE,
    label: SHIPMENT_SUMMARY_LABELS.LOAD_START_TIME,
    type: "datetime",
    format: formatDateTimePicker,
  },
  SHIPMENT_END_DATE: {
    key: dtnShipmentKeys.SHIPMENT_END_DATE,
    label: SHIPMENT_SUMMARY_LABELS.LOAD_END_TIME,
    type: "datetime",
    format: formatDateTimePicker,
  },
  ESTIMATED_VOLUME: {
    key: dtnShipmentKeys.ESTIMATED_VOLUME,
    label: "Estimated shipment max volume",
    format: (value) => `${value?.toLocaleString()} ${INPUT_METRIC}`,
  },
  SOURCE_TANK: {
    key: dtnShipmentKeys.SOURCE_TANK,
    label: "Source tank",
    placeholder: "Enter a tank number",
    type: "input",
  },

  // --- OPTIONAL FIELDS ---
  DESTINATION_TANK: {
    key: dtnShipmentKeys.ADDITIONAL_INFO,
    label: "Shipment sub-type",
    options: ["sub-type A", "sub-type B"],
    isOptional: true,
    type: "select",
  },
  DESTINATION_BATCH_ID: {
    key: dtnShipmentKeys.ADDITIONAL_INFO,
    label: "Shipment sub-type",
    options: ["sub-type A", "sub-type B"],
    isOptional: true,
    type: "select",
  },
  REMAINING_VOLUME: {
    key: dtnShipmentKeys.ADDITIONAL_INFO,
    label: "Shipment sub-type",
    options: ["sub-type A", "sub-type B"],
    isOptional: true,
    type: "select",
  },
  SHIPMENT_SUB_TYPE: {
    key: dtnShipmentKeys.ADDITIONAL_INFO,
    label: "Shipment sub-type",
    options: ["sub-type A", "sub-type B"],
    isOptional: true,
    type: "select",
  },
  NOTES: {
    key: dtnShipmentKeys.ADDITIONAL_INFO,
    label: "Notes",
    placeholder: "Enter your notes here...",
    isOptional: true,
    width: "col-12",
    type: "textarea",
  },
};

const BackToUnitFields = {
  SHIPMENT_ID: {
    key: dtnShipmentKeys.SHIPMENT_ID,
    label: "Shipment ID",
    type: "input",
  },
  SHIPMENT_TYPE: {
    key: dtnShipmentKeys.SHIPMENT_TYPE,
    label: SHIPMENTS_LABELS.SHIPMENT_TYPE,
    format: shipmentTypeToUpperCase,
  },
  SHIPMENT_START_DATE: {
    key: dtnShipmentKeys.SHIPMENT_START_DATE,
    label: SHIPMENT_SUMMARY_LABELS.LOAD_START_TIME,
    type: "datetime",
    format: formatDateTimePicker,
    validation: {required: "Load start is required."},
  },
  SHIPMENT_END_DATE: {
    key: dtnShipmentKeys.SHIPMENT_END_DATE,
    label: SHIPMENT_SUMMARY_LABELS.LOAD_END_TIME,
    type: "datetime",
    format: formatDateTimePicker,
    validation: {required: "Load end is required."},
  },
  SHIPMENT_DATE: {
    key: dtnShipmentKeys.SHIPMENT_DATE,
    label: SHIPMENTS_LABELS.SHIPMENT_DATE,
    format: formatDatePicker,
    type: "date",
    validation: {required: "Date is required"},
  },
  ESTIMATED_VOLUME: {
    key: dtnShipmentKeys.ESTIMATED_VOLUME,
    label: "Volume",
    validation: {required: "Volume is required"},
    placeholder: "Back to unit volume",
    type: "number",
    // format: (value) => `${value?.toLocaleString()} ${INPUT_METRIC}`,
  },
  SOURCE_TANK: {
    key: dtnShipmentKeys.SOURCE_TANK,
    label: "Source tank",
    format: null,
    placeholder: "Enter a tank number",
    validation: {required: "Source tank is required"},
    type: "text",
  },
  NOTES: {
    key: dtnShipmentKeys.ADDITIONAL_INFO,
    label: "Notes",
    placeholder: "Enter your notes here...",
    isOptional: true,
    width: "col-12",
    type: "textarea",
  },
};

const InventoryTransferFields = {
  SHIPMENT_TYPE: {
    key: dtnShipmentKeys.SHIPMENT_TYPE,
    label: SHIPMENTS_LABELS.SHIPMENT_TYPE,
    options: [
      {value: SHIPMENT_TYPE.BACK_TO_UNIT, label: "Back to unit misc"},
      {value: SHIPMENT_TYPE.TRUCK_RACK, label: "Truck rack"},
      {value: SHIPMENT_TYPE.INVENTORY_TRANSFER, label: "Inventory Transfer"},
    ],
    format: shipmentTypeToUpperCase,
    type: "select",
  },
  SOURCE_TANK: {
    key: dtnShipmentKeys.SOURCE_TANK,
    label: "Source tank",
    placeholder: "Enter a tank number",
    validation: {required: "Source tank is required"},
    type: "input",
  },
  SOURCE_BATCH_ID: {
    key: dtnShipmentKeys.SOURCE_BATCH_ID,
    label: "Source batch ID",
    placeholder: "Enter source batch ID",
    validation: {required: "Source batch ID is required"},
    type: "select",
  },
  DESTINATION_TANK: {
    key: dtnShipmentKeys.DESTINATION_TANK,
    label: "Destination tank number",
    placeholder: "Enter destination tank number",
    validation: {required: "Destination tank number is required"},
    type: "input",
  },
  DESTINATION_BATCH_ID: {
    key: dtnShipmentKeys.DESTINATION_BATCH_ID,
    label: "Destination batch ID",
    placeholder: "Enter destination batch ID",
    validation: {required: "Destination batch ID is required"},
    type: "select",
  },
  SHIPMENT_START_DATE: {
    key: dtnShipmentKeys.SHIPMENT_START_DATE,
    label: "Shipment date/time start",
    type: "datetime",
    format: formatDateTimePicker,
  },
  SHIPMENT_END_DATE: {
    key: dtnShipmentKeys.SHIPMENT_END_DATE,
    label: "Shipment date/time end",
    type: "datetime",
    format: formatDateTimePicker,
  },
  ADJUSTED_VOLUME: {
    key: dtnShipmentKeys.ADJUSTED_VOLUME,
    label: "Volume",
    type: "number",
    placeholder: "Enter volume in bbl",
    validation: {required: "Volume is required"},
  },
  REMAINING_VOLUME: {
    key: dtnShipmentKeys.REMAINING_VOLUME,
    label: "Remaining volume source batch",
    type: "readonly",
    format: (value) => `${value?.toLocaleString()} ${INPUT_METRIC}`,
  },
  SHIPMENT_ID: {
    key: dtnShipmentKeys.SHIPMENT_ID,
    label: "Shipment ID",
    type: "readonly",
    format: (value) => `${value}`,
  },
  NOTES: {
    key: dtnShipmentKeys.ADDITIONAL_INFO,
    label: "Notes",
    placeholder: "Enter your notes here...",
    type: "textarea",
    isOptional: true,
    width: "col-12",
  },
};

/**
 * Common field values shared by dtn_shipment types.
 * @const {!Object<string, *>}
 */
const commonDefaults = {
  [dtnShipmentKeys.SHIPMENT_ID]: "",
  [dtnShipmentKeys.SHIPMENT_TYPE]: "",
  [dtnShipmentKeys.ESTIMATED_VOLUME]: 0,
  [dtnShipmentKeys.ADDITIONAL_INFO]: "",
  [dtnShipmentKeys.SOURCE_TANK]: "",
};

/**
 * Defines default values for dtn_shipment fields based on shipment type.
 * @const {!Object<SHIPMENT_TYPE, !Object<string, *>>}
 */
const DefaultsByShipmentType = {
  [SHIPMENT_TYPE.BACK_TO_UNIT]: {
    ...commonDefaults,
    [dtnShipmentKeys.SHIPMENT_DATE]: "",
    [dtnShipmentKeys.SHIPMENT_START_DATE]: "",
    [dtnShipmentKeys.SHIPMENT_END_DATE]: "",
  },
  [SHIPMENT_TYPE.TRUCK_RACK]: {
    ...commonDefaults,
    [dtnShipmentKeys.SHIPMENT_DATE]: "",
    [dtnShipmentKeys.SHIPMENT_START_DATE]: "",
    [dtnShipmentKeys.SHIPMENT_END_DATE]: "",
  },
  [SHIPMENT_TYPE.INVENTORY_TRANSFER]: {
    [dtnShipmentKeys.SHIPMENT_DATE]: "",
    [dtnShipmentKeys.SHIPMENT_START_DATE]: "",
    [dtnShipmentKeys.SHIPMENT_END_DATE]: "",
  },
};

export {
  FIRST_STEP,
  InitialShipmentState,
  DefaultsByShipmentType,
  TruckRackFields,
  BackToUnitFields,
  InventoryTransferFields,
};
