import {useQuery} from "@apollo/client";
import PropTypes from "prop-types";
import {useMemo, useEffect} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {FormGroup} from "reactstrap";
import {
  OP_CONDITIONS,
  ADD_BATCH_FORM,
} from "modules/co-processing/constants/batches";
import {GET_BATCH_IDS, GET_VALVE_STATUS} from "graphql/coprocessing/batches";
import {convertPSTtoUTC} from "modules/co-processing/helpers/formBuilder";
import {displayDate, toPST} from "modules/co-processing/helpers/dateHelper";
import PIData from "../../Shared/PIData";
import extractBatchIdDetails from "../extractBatchIdDetails";
import {DHDSDateTime, Notes, Form} from "../FormElements";

const {keys: fieldKeys, labels: fieldLabels} = ADD_BATCH_FORM;

const BatchIDSelect = ({startTime, endTime, tankNumber, bypassed = false}) => {
  const {setValue} = useFormContext();

  const {data, loading} = useQuery(GET_BATCH_IDS, {
    fetchPolicy: "network-only",
    variables: {startTime, endTime, tankNumber},
  });

  const batchOptions = useMemo(() => {
    if (!data?.bioLcCoproUsBatchesApi?.body?.batch_ids) return [];
    return data.bioLcCoproUsBatchesApi.body.batch_ids.map((b) => {
      const baseOptions = {
        value: b.batch_id,
        label: `${b.batch_id} ${displayDate(b.tank_certified_date)}`,
        batchCode: b?.batch_code,
        sourceSampleCode: b?.source_sample_code
          ? JSON.parse(b.source_sample_code)
          : [],
        tankCertifiedDate: b?.tank_certified_date,
      };

      if (bypassed) {
        const bypassSuffix = "_bypass";

        baseOptions.label = b.batch_id;
        baseOptions.value += bypassSuffix;
        baseOptions.batchCode += bypassSuffix;
      }

      return baseOptions;
    });
  }, [data, bypassed]);

  const selectedBatchId = useWatch({name: fieldKeys.batchId});

  useEffect(() => {
    const batch = batchOptions?.find((o) => o?.value === selectedBatchId);

    if (batch) {
      setValue(fieldKeys.batchCode, batch?.batchCode);
      setValue(fieldKeys.tankCertifiedDate, toPST(batch?.tankCertifiedDate));
      setValue(fieldKeys.sourceSampleCode, batch?.sourceSampleCode);
    }
  }, [batchOptions, selectedBatchId, setValue]);

  return (
    <FormGroup>
      <Form.Select
        className="col-sm-8"
        isLoading={loading}
        label={fieldLabels.batchId}
        name={fieldKeys.batchId}
        options={batchOptions}
      />
    </FormGroup>
  );
};

const TAGS = {
  HEELCL: "HEELCL (clearout)",
  SD: "SD (shut down)",
  SU: "SU (start up)",
  NR: "NR (nonrenewable)",
  OTHER: "Other",
};

const BatchTags = () => {
  const {setValue} = useFormContext();
  const selectedBatchTag = useWatch({name: "batch_tag"});
  const heelVolume = useWatch({name: "heel_volume"});

  const options = Object.entries(TAGS).map(([value, label]) => ({
    value,
    label,
  }));

  useEffect(() => {
    if (heelVolume) {
      setValue("batch_tag", "HEELCL");
    }
  }, [heelVolume, setValue]);

  return (
    <FormGroup>
      <Form.Select
        label="Batch Tags"
        name="batch_tag"
        options={options}
        defaultValue={selectedBatchTag || ""}
        disabled={heelVolume}
        isOptional
      />
    </FormGroup>
  );
};

const SaveBatch = () => {
  const {getValues, setValue} = useFormContext();
  const [tankNumber, opCondition, startTime, endTime, isSplitBatch] = useWatch({
    name: [
      fieldKeys.tankNo,
      fieldKeys.opCondition,
      fieldKeys.batchStartTime,
      fieldKeys.batchEndTime,
      fieldKeys.splitBatch,
    ],
  });

  const bypassed = opCondition === OP_CONDITIONS.BY_PASSED;

  const [startTimePST, endTimePST] = useMemo(
    () => [convertPSTtoUTC(startTime), convertPSTtoUTC(endTime)],
    [startTime, endTime],
  );

  useQuery(GET_VALVE_STATUS, {
    fetchPolicy: "network-only",
    variables: {start_time: startTimePST, end_time: endTimePST},
    onCompleted: (data) => {
      const valveOpen = data?.bioLcCoproUsBatchesApi?.body?.valve_open; // True implies valve is open.
      const operatingCondition = valveOpen
        ? OP_CONDITIONS.NORMAL_UNIT_REC
        : OP_CONDITIONS.NORMAL;

      setValue("valveOpen", valveOpen);
      setValue(fieldKeys.opCondition, operatingCondition);
    },
    skip: bypassed || !startTime || !endTime,
  });

  // If batch is split, render DHDSDateTime with split batch id prefix
  if (isSplitBatch) {
    const batchId = getValues(fieldKeys.batchId);

    return (
      <>
        <div className="row g-6 mb-4">
          <DHDSDateTime
            prefix={`Split ${extractBatchIdDetails(batchId)?.suffix} `}
          />
        </div>
        <Notes />
        {!!isSplitBatch && <PIData />}
      </>
    );
  }

  return (
    <>
      <BatchIDSelect
        bypassed={bypassed}
        startTime={startTime}
        endTime={endTime}
        tankNumber={tankNumber}
      />
      <BatchTags />
      <Notes />
    </>
  );
};

export default SaveBatch;

BatchIDSelect.propTypes = {
  tankNumber: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  bypassed: PropTypes.bool,
};
